// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasze-metody-elektroterapia-js": () => import("./../src/pages/nasze-metody/elektroterapia.js" /* webpackChunkName: "component---src-pages-nasze-metody-elektroterapia-js" */),
  "component---src-pages-nasze-metody-kinesiology-taping-js": () => import("./../src/pages/nasze-metody/kinesiology-taping.js" /* webpackChunkName: "component---src-pages-nasze-metody-kinesiology-taping-js" */),
  "component---src-pages-nasze-metody-masaz-js": () => import("./../src/pages/nasze-metody/masaz.js" /* webpackChunkName: "component---src-pages-nasze-metody-masaz-js" */),
  "component---src-pages-nasze-metody-taping-sztywny-js": () => import("./../src/pages/nasze-metody/taping-sztywny.js" /* webpackChunkName: "component---src-pages-nasze-metody-taping-sztywny-js" */),
  "component---src-pages-nasze-metody-techniki-osteopatyczne-js": () => import("./../src/pages/nasze-metody/techniki-osteopatyczne.js" /* webpackChunkName: "component---src-pages-nasze-metody-techniki-osteopatyczne-js" */),
  "component---src-pages-nasze-metody-terapia-swiatlem-js": () => import("./../src/pages/nasze-metody/terapia-swiatlem.js" /* webpackChunkName: "component---src-pages-nasze-metody-terapia-swiatlem-js" */),
  "component---src-pages-nasze-metody-ultradzwieki-js": () => import("./../src/pages/nasze-metody/ultradzwieki.js" /* webpackChunkName: "component---src-pages-nasze-metody-ultradzwieki-js" */),
  "component---src-pages-nasze-oddzialy-js": () => import("./../src/pages/nasze-oddzialy.js" /* webpackChunkName: "component---src-pages-nasze-oddzialy-js" */),
  "component---src-pages-o-firmie-js": () => import("./../src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */),
  "component---src-pages-o-nas-js": () => import("./../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-js": () => import("./../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

